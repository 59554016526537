import React from 'react';

export default function Hero() {
    return (
        <>
            {/* <video autoPlay muted='true' loop='true' id='heroVid'>
                <source src={process.env.PUBLIC_URL + 'vid/GH010455.MP4'} type='video/mp4' />
            </video> */}
            <div className='hero'>
                <div className='hero-text'>
                    <h1>Crafts by Mads</h1>
                </div>
            </div>
        </>
    );
};
