import React from 'react';
import { Container, Col } from 'react-bootstrap';
import ContactForm from '../components/ContactForm';

export default function Contact() {
    return (
        <Container fluid className='page'>
            <Col>
                <h1 className='header'>Contact Us</h1>
                <br />
                <Col sm={12} md={10} lg={8}>
                    <ContactForm />
                </Col>
            </Col>
        </Container>
    )
}
