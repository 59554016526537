import React from 'react';
import { Col, Container } from 'react-bootstrap';

export default function Error() {
    return (
        <Container fluid className='page'>
            <Col sm={12} md={10} lg={8}>
                <h1>404</h1>
                <br />
                <h1>Lost amoung the crafts? Quick grab my <a href='/'>macrame</a>!</h1>
            </Col>
        </Container>
    );
}