import React, { useState } from 'react';
import { Row, Form, Button } from 'react-bootstrap';

const encode = (data) => {
    return Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
};

export default function ContactForm() {
    const [formState, setFormState] = useState({
        name: '',
        email: '',
        message: '',
    });

    const handleSubmit = (e) => {
        fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': 'contact', ...formState }),
        })
            .then(() => alert('Success!'))
            .catch((error) => alert(error));

        e.preventDefault();
    };
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group controlId='contactMeForm'>
                <Row className='mb-3'>
                    <Form.Control
                        type='text'
                        placeholder='Name'
                        name='name'
                        onChange={(e) =>
                            setFormState({ ...formState, name: e.target.value })
                        }
                    />
                </Row>
                <Row className='mb-3'>
                    <Form.Control
                        type='email'
                        placeholder='Email'
                        name='email'
                        onChange={(e) =>
                            setFormState({ ...formState, email: e.target.value })
                        }
                    />
                </Row>
                <Row className='mb-3'>
                    <Form.Control
                        as='textarea'
                        placeholder='Message'
                        name='message'
                        rows={3}
                        onChange={(e) =>
                            setFormState({ ...formState, message: e.target.value })
                        }
                    />
                </Row>
                <Button variant='dark' type='submit' className='ml-n2'>
                    Submit
        </Button>
            </Form.Group>
        </Form>
    )
}
