import React from 'react';
import { Container, Col } from 'react-bootstrap';

export default function About() {
    return (
        <Container fluid className='page'>
            <Col>
            <h1 className='header'>About Us</h1>
            </Col>
        </Container>
    )
}